import React from "react";
import { withStyles } from "@material-ui/core";
import ArticleCard from "../article-card";
import styles from "./article-cards.styles";

const ArticleCards = ({ articles, classes }) => {
  return (
    <div className={classes.cardGrid}>
      {articles &&
        articles.map((article, index) => (
          <ArticleCard key={index} {...article} />
        ))}
    </div>
  );
};

export default withStyles(styles, { name: "ArticleCards" })(ArticleCards);
