import React from "react";
import { Grid, Box, withStyles } from "@material-ui/core";
import styles from "../footer/footer.styles";
import { getLanguageValues, getSectionTitles, splitList } from "../../util";

const Footer = ({ footer, classes }) => {
  const {
    categories,
    copyright,
    email,
    footerLogo,
    socials,
    links,
    address,
  } = footer;
  const [
    articlesLocalized,
    aboutUsLocalized,
    contactUsLocalized,
  ] = getSectionTitles();

  const socialIcons = socials.map((social, idx) => (
    <Grid item key={social.name + idx}>
      <a className={classes.socialLink} href={social.link} target="_blank">
        <i className={`fab fa-${social.name} fa-2x`}></i>
      </a>
    </Grid>
  ));

  const categoryLinks = categories.map((category, idx) => {
    const { name, nameTc, nameSc } = category;
    const nameLocalized = getLanguageValues(name, nameTc, nameSc);
    return (
      <Grid item key={idx} className={classes.footerLink}>
        <a key={idx} href={`/category/${category.slug}`}>
          {nameLocalized}
        </a>
      </Grid>
    );
  });

  const [catLinksOne, catLinksTwo] = splitList(categoryLinks);

  const pageLinks = links.map((link, idx) => {
    const { name, nameTc, nameSc } = link;
    const nameLocalized = getLanguageValues(name, nameTc, nameSc);
    return (
      <Grid item key={idx} className={classes.footerLink}>
        <a key={idx} href={`/${link.slug}`}>
          {nameLocalized}
        </a>
      </Grid>
    );
  });

  return (
    <div className={classes.root}>
      <div className={classes.footerContainer}>
        {footerLogo && (
          <div className={classes.footerLogo}>
            <a href="/" className={classes.footerLogoLink}>
              <img src={footerLogo.url} />
            </a>
          </div>
        )}
        <Grid
          container
          alignItems="center"
          justifyContent="flex-start"
          className={classes.socialsContainer}
        >
          {socials && socialIcons}
        </Grid>
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          className={classes.footerSectionContainer}
        >
          <Box className={classes.footerSection}>
            <h3 className={classes.sectionTitle}>{articlesLocalized}</h3>
            <div className={classes.linksContainer}>
              {catLinksOne && (
                <div className={classes.linksList}>{catLinksOne}</div>
              )}
              {catLinksTwo && (
                <div className={classes.linksList}>{catLinksTwo}</div>
              )}
            </div>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-start"
            className={classes.footerSection}
          >
            <h3 className={classes.sectionTitle}>{aboutUsLocalized}</h3>
            {links && pageLinks}
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-start"
            className={classes.footerSection}
          >
            <h3 className={classes.sectionTitle}>{contactUsLocalized}</h3>
            {email && <p className={classes.footerItem}>{email}</p>}
            {address && <p className={classes.footerItem}>{address}</p>}
          </Box>
        </Grid>
      </div>
      <div className={classes.footerBottom}>
        <div className={classes.footerInfo}>
          {copyright && <p className={classes.copyright}>{copyright}</p>}
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles, { name: "WindFooter" })(Footer);
