export default (theme) => ({
  root: {
    marginTop: "30px",
    maxWidth: "80%",
    margin: "0 auto",
  },
  adLink: {
    width: "100%",
  },
  adImg: {
    width: "100%",
    objectFit: "cover",
  },
});
