import React, { Fragment } from "react";
import Article from "../article";
import ArticleCards from "../article-cards";
import WideArticles from "../wide-articles";

const HomeArticles = ({ articles }) => {
  const bannerArticle = articles[0];
  const articleCards = articles.slice(1, 3);
  const wideArticles = articles.slice(3);
  return (
    <>
      {bannerArticle && <Article article={bannerArticle} fullWidthImg />}
      {articleCards && <ArticleCards articles={articleCards} />}
      {wideArticles && <WideArticles articles={wideArticles} />}
    </>
  );
};

export default HomeArticles;
