export default (theme) => ({
  root: {},
  description: {},
  areaBanner: {
    padding: "24px 0",
  },
  areaName: {
    margin: 0,
  },
  placesContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
  },
  place: {
    width: "300px",
    marginTop: "20px",
    marginBottom: "20px",
    marginRight: "20px",
  },
  placeName: {
    marginTop: 0,
    marginBottom: "10px",
  },
  placeAddress: {
    margin: 0,
  },
  cityZip: {
    margin: 0,
  },
});
