import React, { useState, useEffect, Fragment } from "react";
import ContentModel from "../../model";
import { NotFound } from "../../components";
import { NOT_FOUND_SLUG } from "../../constants";

const NotFoundPage = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await ContentModel.fetchBySlug(NOT_FOUND_SLUG);
      setContent(response);
    };

    fetchData();
  }, []);

  if (!content) return null;

  return (
    <>
      <NotFound {...content} />
    </>
  );
};

export default NotFoundPage;
