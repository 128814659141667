import React from "react";
import { Grid, Menu, makeStyles, withStyles } from "@material-ui/core";
import styles from "./mobile-menu.styles";
import { getLanguageValues, getSectionTitles, splitList } from "../../util";
import LanguageSwitcherMobile from "../language/language-switcher-mobile";

const useLanguageSwitcherMobileStyles = makeStyles((theme) => ({
  accordion: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const MobileMenu = (props) => {
  const { anchorEl, open, handleMenuClose, categories, links, classes } = props;
  const [articlesLocalized, aboutUsLocalized] = getSectionTitles();
  const languageSwitcherMobileClasses = useLanguageSwitcherMobileStyles();

  const categoryLinks = categories.map((cat, idx) => (
    <Grid item key={idx} className={classes.menuLink}>
      <a href={`/category/${cat.slug}`} onClick={handleMenuClose}>
        {getLanguageValues(cat.name, cat.nameTc, cat.nameSc)}
      </a>
    </Grid>
  ));

  const [catLinksOne, catLinksTwo] = splitList(categoryLinks);

  const headerLinks = links.map((link, idx) => (
    <Grid item key={idx} className={classes.menuLink}>
      <a href={`/${link.slug}`} onClick={handleMenuClose}>
        {getLanguageValues(link.name, link.nameTc, link.nameSc)}
      </a>
    </Grid>
  ));

  return (
    <Menu
      id="mobile-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleMenuClose}
      PopoverClasses={{ paper: classes.popoverPaper }}
      className={classes.mobileMenu}
    >
      <div className={classes.mobileMenuSection}>
        {articlesLocalized && (
          <h3 className={classes.sectionTitle}>{articlesLocalized}</h3>
        )}
        {categoryLinks && (
          <div className={classes.splitLinks}>
            <div className={classes.linksContainer}>{catLinksOne}</div>
            <div className={classes.linksContainer}>{catLinksTwo}</div>
          </div>
        )}
      </div>
      <div className={classes.mobileMenuSection}>
        {aboutUsLocalized && (
          <h3 className={classes.sectionTitle}>{aboutUsLocalized}</h3>
        )}
        {headerLinks && (
          <div className={classes.linksContainer}>{headerLinks}</div>
        )}
      </div>
      <div className={classes.mobileMenuSection}>
        <LanguageSwitcherMobile
          classes={languageSwitcherMobileClasses}
          handleMenuClose={handleMenuClose}
        />
      </div>
    </Menu>
  );
};

export default withStyles(styles, { name: "MobileMenu" })(MobileMenu);
