import React from "react";
import { getLanguageValues } from "../../util";

const Description = ({ description, descriptionTc, descriptionSc }) => {
  const descriptionLocalized = getLanguageValues(
    description,
    descriptionTc,
    descriptionSc
  );

  return <h2>{descriptionLocalized}</h2>;
};

export default Description;
