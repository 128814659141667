export default (theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      height: ({ hideSubNav }) => (hideSubNav ? "110px" : "150px"),
    },
    height: "96px",
  },
  appBar: {
    boxShadow: "none",
  },
  desktopContainer: {
    width: "100%",
    maxWidth: "1260px",
    margin: "0 auto",
    height: "110px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "24px",
    paddingRight: "24px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  },
  headerLogo: {},
  headerLogoLink: {
    "& img": {
      width: "320px",
      [theme.breakpoints.down("sm")]: {
        width: "250px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "230px",
      },
    },
  },
  languageSelect: {
    height: "30px",
  },
  linkContainer: {
    backgroundColor: "#E8ECEE",
    height: "40px",
    display: "flex",
    alignItems: "center",
  },
  gridContainer: {
    width: "100%",
    maxWidth: "1260px",
    margin: "0 auto",
    paddingLeft: "24px",
    paddingRight: "24px",
  },
  menuLink: {
    color: "#000000",
    fontSize: "16px",
    marginTop: 0,
    marginBottom: 0,
    marginRight: "24px",
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.primary.secondary,
    },
  },
  mobileContainer: {
    height: "96px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "24px",
    paddingRight: "24px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    [theme.breakpoints.down(380)]: {
      paddingLeft: "6px",
      paddingRight: "6px",
    },
  },
  menuToggler: {
    color: "#FFF",
    paddingLeft: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  togglerIcon: {
    fontSize: "32px",
  },
  mobileSpacer: {
    height: "56px",
    width: "56px",
  },
});
