import React from "react";
import { Container, Hidden, makeStyles } from "@material-ui/core";
import { Sidebar } from "../components";

const useStyles = makeStyles((theme) => ({
  root: {},
  sidebarPage: {
    [theme.breakpoints.up("xl")]: {
      display: "flex",
      justifyContent: "space-between",
    },
  },
}));

const SidebarPage = ({ sidebarProps, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth={false}>
        <div className={classes.sidebarPage}>
          {children}
          <Hidden lgDown>
            <Sidebar sidebarProps={sidebarProps} />
          </Hidden>
        </div>
      </Container>
    </div>
  );
};

export default SidebarPage;
