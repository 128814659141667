export default (theme) => ({
  root: {
    width: "100%",
  },
  articlesHeader: {
    color: theme.palette.primary.altPurple,
    paddingLeft: "24px",
    marginTop: 0,
    marginBottom: "10px",
  },
  latestArticle: {
    paddingTop: "10px",
    paddingBottom: "10px",
    "&:last-of-type": {},
  },
  articleTop: {
    display: "flex",
    alignItems: "center",
  },
  articleIcon: {
    marginRight: "8px",
    color: theme.palette.primary.altPurple,
    fontSize: "16px",
  },
  articleLink: {
    "& p": {
      fontSize: "15px",
      fontWeight: 600,
      marginTop: 0,
      marginBottom: "4px",
      "&:hover": {
        color: theme.palette.primary.gray,
      },
    },
  },
  articleDate: {
    fontSize: "15px",
    color: theme.palette.primary.secondary,
    margin: 0,
    textAlign: "right",
  },
});
