export default (theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
  },
  footerContainer: {
    minHeight: "300px",
    width: "100%",
    maxWidth: "1260px",
    margin: "0 auto",
    paddingLeft: "24px",
    paddingRight: "24px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  },
  footerLogo: {
    paddingTop: "30px",
    paddingBottom: "20px",
  },
  footerLogoLink: {
    "& img": {
      height: "70px",
    },
  },
  socialsContainer: {},
  socialLink: {
    fontSize: "14px",
    marginRight: "30px",
    "& i": {
      color: "#FFF",
      "&:hover": {
        color: theme.palette.primary.secondary,
      },
    },
  },
  footerSectionContainer: {
    margin: "30px 0",
    [theme.breakpoints.down("sm")]: {
      margin: "20px 0",
    },
  },
  footerSection: {
    width: "100%",
    maxWidth: "300px",
    padding: "20px 0",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 0",
    },
  },
  sectionTitle: {
    marginTop: 0,
    marginBottom: "6px",
  },
  linksContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
    },
  },
  linksList: {
    [theme.breakpoints.up("md")]: {
      marginRight: "80px",
    },
  },
  footerLink: {
    marginTop: "12px",
    "& a": {
      color: "#FFF",
      fontSize: "14px",
      lineHeight: "20px",
      transition: "0.1s ease-in",
      borderBottom: "1px solid transparent",
      "&:hover": {
        borderBottom: "1px solid #FFF",
      },
    },
  },
  footerBottom: {
    backgroundColor: theme.palette.primary.darkBlueGray,
    height: "40px",
    display: "flex",
    alignItems: "center",
  },
  footerInfo: {
    width: "100%",
    maxWidth: "1260px",
    margin: "0 auto",
    paddingLeft: "24px",
    paddingRight: "24px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  },
  footerItem: {
    marginTop: "12px",
    marginBottom: 0,
    color: "#FFF",
    fontSize: "14px",
    lineHeight: "20px",
  },
  copyright: {
    color: "#FFFFFF",
    fontSize: "13px",
    margin: 0,
  },
});
