import React, { useState, useEffect, Fragment } from "react";
import { withStyles } from "@material-ui/core";
import ReactMarkdown from "react-markdown";
import Slider from "react-slick";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import styles from "./article-component.styles";
import AdCarousel from "../ad-carousel";
import LinkRenderer from "../../renderers/link-renderer";
import { formatDate, getLanguageValues } from "../../util";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ArticleComponent = ({
  adCarousel,
  author,
  category,
  content,
  contentSc,
  contentTc,
  createdAt,
  headline,
  headlineSc,
  headlineTc,
  photos,
  updated_at,
  classes,
}) => {
  const [pageHref, setPageHref] = useState("");

  useEffect(() => {
    setPageHref(window.location.href);
  }, []);

  const headlineLocalized = getLanguageValues(headline, headlineTc, headlineSc);
  const contentLocalized = getLanguageValues(content, contentTc, contentSc);
  const categoryLocalized = getLanguageValues(
    category.name,
    category.nameTc,
    category.nameSc
  );

  const settings = {
    className: classes.carousel,
    dots: true,
    infinite: false,
    speed: 500,
  };

  return (
    <div className={classes.root}>
      <div className={classes.articleInfo}>
        {category && (
          <h2 className={classes.articleCategory}>
            <a href={`/category/${category.slug}`}>{categoryLocalized}</a>
          </h2>
        )}
        {headline && <h1 className={classes.headline}>{headlineLocalized}</h1>}
        <div className={classes.mainInfo}>
          {author && <p className={classes.author}>By {author}</p>}
          <div className={classes.timestamps}>
            {createdAt && (
              <p className={classes.created}>{formatDate(createdAt)}</p>
            )}
            {updated_at && (
              <p className={classes.updated}>
                Updated: {formatDate(updated_at, true)}
              </p>
            )}
          </div>
        </div>
      </div>
      <Slider {...settings} className={classes.carousel}>
        {photos.map((photo, index) => {
          const captionLocalized = getLanguageValues(
            photo.caption,
            photo.captionTc,
            photo.captionSc
          );
          return (
            photo && (
              <div key={index} id={index} className={classes.slide}>
                <img src={photo.photo.url} className={classes.slidePhoto} />
                <p className={classes.caption}>{captionLocalized}</p>
              </div>
            )
          );
        })}
      </Slider>
      <div className={classes.articleContent}>
        <ReactMarkdown
          children={contentLocalized}
          renderers={{ link: LinkRenderer }}
        />
      </div>
      <div className={classes.shareText}>
        <p>Share</p>
      </div>
      <div className={classes.socialShareContainer}>
        <FacebookShareButton
          url={pageHref}
          className={classes.socialShareButton}
        >
          <i className="fab fa-facebook fa-2x" />
        </FacebookShareButton>
        <TwitterShareButton
          url={pageHref}
          className={classes.socialShareButton}
        >
          <i className="fab fa-twitter fa-2x" />
        </TwitterShareButton>
        <LinkedinShareButton
          url={pageHref}
          className={classes.socialShareButton}
        >
          <i className="fab fa-linkedin fa-2x" />
        </LinkedinShareButton>
      </div>
      <AdCarousel ads={adCarousel} />
    </div>
  );
};

export default withStyles(styles, { name: "ArticleComponent" })(
  ArticleComponent
);
