import React, { useState, useEffect, Fragment } from "react";
import ContentModel from "../../model";
import { Container } from "@material-ui/core";
import { Metadata, PageBanner, WeeklyIssue } from "../../components";

const slug = "weekly";

const WeeklyIssuePage = (props) => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await ContentModel.fetchBySlug(slug);
      setContent(response);
    };

    fetchData();
  }, []);

  if (!content) return null;

  const {
    metaTitle,
    metaDescription,
    pages,
    title,
    titleTc,
    titleSc,
  } = content;

  const titleMeta = metaTitle || "Wind Newspaper | 風報";
  const description = metaDescription || "";

  return (
    <>
      <Metadata title={titleMeta} description={description} />
      <PageBanner name={title} nameTc={titleTc} nameSc={titleSc} />
      <Container maxWidth={false}>
        <WeeklyIssue pages={pages} />
      </Container>
    </>
  );
};

export default WeeklyIssuePage;
