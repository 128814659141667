import React from "react";
import { Drawer, Grid, IconButton, withStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import styles from "./menu-drawer.styles";
import LanguageSwitcherMobile from "../language/language-switcher-mobile";
import { getLanguageValues, getSectionTitles, splitList } from "../../util";

const MenuDrawer = (props) => {
  const { categories, classes, links, menuOpen, toggleDrawer } = props;
  const [articlesLocalized, aboutUsLocalized] = getSectionTitles();

  const categoryLinks = categories.map((cat, idx) => (
    <Grid item key={idx} className={classes.menuLink}>
      <a href={`/category/${cat.slug}`} onClick={toggleDrawer}>
        {getLanguageValues(cat.name, cat.nameTc, cat.nameSc)}
      </a>
    </Grid>
  ));

  const [catLinksOne, catLinksTwo] = splitList(categoryLinks);

  const headerLinks = links.map((link, idx) => (
    <Grid item key={idx} className={classes.menuLink}>
      <a href={`/${link.slug}`} onClick={toggleDrawer}>
        {getLanguageValues(link.name, link.nameTc, link.nameSc)}
      </a>
    </Grid>
  ));

  return (
    <div className={classes.root}>
      <Drawer
        anchor="left"
        classes={{ root: classes.overlay, paper: classes.drawer }}
        onClose={toggleDrawer}
        open={menuOpen}
      >
        <div className={classes.iconContainer}>
          <IconButton
            disableFocusRipple
            disableRipple
            className={classes.drawerToggler}
            onClick={toggleDrawer}
          >
            <CloseIcon className={classes.drawerIcon} />
          </IconButton>
        </div>
        <div className={classes.drawerSection}>
          {articlesLocalized && (
            <h3 className={classes.sectionTitle}>{articlesLocalized}</h3>
          )}
          {categoryLinks && (
            <div className={classes.splitLinks}>
              <div className={classes.linksContainer}>{catLinksOne}</div>
              <div className={classes.linksContainer}>{catLinksTwo}</div>
            </div>
          )}
        </div>
        <div className={classes.drawerSection}>
          {aboutUsLocalized && (
            <h3 className={classes.sectionTitle}>{aboutUsLocalized}</h3>
          )}
          {headerLinks && (
            <div className={classes.linksContainer}>{headerLinks}</div>
          )}
        </div>
        <div className={classes.drawerSection}>
          <LanguageSwitcherMobile toggleDrawer={toggleDrawer} />
        </div>
      </Drawer>
    </div>
  );
};

export default withStyles(styles, { name: "MenuDrawer" })(MenuDrawer);
