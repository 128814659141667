import React from "react";
import { withStyles } from "@material-ui/core";
import styles from "./ad-banner.styles";
import ConditionalWrapper from "../conditional-wrapper";

const AdBanner = (props) => {
  const { classes, image, link, inCarousel } = props;

  return (
    <div className={classes.root}>
      {!inCarousel && <div className={classes.adLabel}>Advertisement</div>}
      <ConditionalWrapper link={link} linkClass={classes.adLink}>
        {image && image.url && (
          <img className={classes.adImg} src={image.url} />
        )}
      </ConditionalWrapper>
    </div>
  );
};

export default withStyles(styles, { name: "AdBanner" })(AdBanner);
