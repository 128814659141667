export default (theme) => ({
  root: {},
  contentContainer: {
    maxWidth: "1260px",
    margin: "0 auto",
    paddingTop: "24px",
    paddingLeft: "24px",
    paddingRight: "24px",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column-reverse",
      padding: 0,
    },
  },
  articleInfo: {
    maxWidth: "400px",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "100%",
      padding: "0 24px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 20px",
    },
  },
  articleLink: {
    fontSize: "38px",
    fontWeight: 500,
    marginTop: 0,
    "&:hover": {
      color: theme.palette.primary.darkGray,
    },
    [theme.breakpoints.down("lg")]: {
      marginTop: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
      lineHeight: "36px",
    },
  },
  photoContainer: {
    maxWidth: "700px",
    paddingLeft: "24px",
    [theme.breakpoints.down("lg")]: {
      margin: "0 auto",
      maxWidth: "100%",
      paddingRight: "24px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  },
  imgLink: {},
  mainPhoto: {
    width: "100%",
    objectFit: "cover",
  },
  createdAt: {},
  snippet: {
    lineHeight: "20px",
  },
});
