import React from "react";
import ReactMarkdown from "react-markdown";
import { useTheme, useMediaQuery, withStyles } from "@material-ui/core";
import styles from "./banner-article.styles";
import { formatDate, getFirstParagraph, getLanguageValues } from "../../util";
import FullBleed from "../full-bleed";
import LinkRenderer from "../../renderers/link-renderer";

const BannerArticle = ({ article, classes }) => {
  const {
    content,
    contentTc,
    contentSc,
    createdAt,
    headline,
    headlineTc,
    headlineSc,
    photos,
    slug,
  } = article;

  const theme = useTheme();
  const isSmallBreakpoint = useMediaQuery(theme.breakpoints.down("sm"));
  const mainPhoto = photos[0];
  const headlineLocalized = getLanguageValues(headline, headlineTc, headlineSc);
  const contentLocalized = getLanguageValues(content, contentTc, contentSc);
  const snippet = getFirstParagraph(contentLocalized);

  return (
    <div className={classes.root}>
      <div className={classes.contentContainer}>
        <div className={classes.articleInfo}>
          {headlineLocalized && (
            <h1 className={classes.articleLink}>
              <a href={`/article/${slug}`}>{headlineLocalized}</a>
            </h1>
          )}
          {createdAt && (
            <h4 className={classes.createdAt}>{formatDate(createdAt)}</h4>
          )}
          {snippet && (
            <div className={classes.snippet}>
              <ReactMarkdown
                children={snippet}
                renderers={{ link: LinkRenderer }}
              />
            </div>
          )}
        </div>
        {mainPhoto && (
          <div className={classes.photoContainer}>
            <FullBleed fullWidth={isSmallBreakpoint}>
              <a className={classes.imgLink} href={`/article/${slug}`}>
                <img className={classes.mainPhoto} src={mainPhoto.photo.url} />
              </a>
            </FullBleed>
          </div>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles, { name: "BannerArticle" })(BannerArticle);
