import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  withStyles,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RemoveIcon from "@material-ui/icons/Remove";
import { useLanguageProvider } from "./language-provider";
import styles from "./language-switcher-mobile.styles";
import { LANGUAGE, LANGUAGE_FORMAT } from "../../constants";
import { getLanguageValues } from "../../util";

const LanguageSwitcherMobile = (props) => {
  const { classes, handleMenuClose, toggleDrawer } = props;
  const [expanded, setExpanded] = useState(true);
  const { language, setCurrentLanguage } = useLanguageProvider();

  const handleExpand = (e, isExpanded) => {
    setExpanded(isExpanded ? true : false);
  };

  const handleLanguageSelect = (e, language) => {
    setCurrentLanguage(language);

    if (toggleDrawer) {
      toggleDrawer(e);
      return;
    }
    if (handleMenuClose) {
      handleMenuClose();
      return;
    }
  };

  const languageItems =
    language &&
    Object.keys(LANGUAGE_FORMAT).map((lang, idx) => {
      const languageOption = LANGUAGE_FORMAT[lang];
      const isActiveLanguage = lang === language;

      return (
        <Grid
          item
          key={idx}
          className={`${classes.languageOption} ${
            isActiveLanguage ? classes.activeLanguage : ""
          }`}
        >
          <p onClick={(e) => handleLanguageSelect(e, lang)}>{languageOption}</p>
          {isActiveLanguage && (
            <CheckCircleIcon className={classes.activeLangIcon} />
          )}
        </Grid>
      );
    });

  return (
    <div className={classes.root}>
      <Accordion
        className={classes.accordion}
        expanded={expanded}
        onChange={handleExpand}
      >
        <AccordionSummary
          classes={{
            root: classes.languageSummary,
            content: classes.summaryContent,
            expanded: classes.summaryExpanded,
            expandIcon: classes.summaryIcon,
          }}
          expandIcon={expanded ? <RemoveIcon /> : <AddIcon />}
        >
          <h3>{getLanguageValues(LANGUAGE.en, LANGUAGE.tc, LANGUAGE.sc)}</h3>
        </AccordionSummary>
        <AccordionDetails className={classes.languageDetails}>
          {languageItems}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default withStyles(styles, { name: "LanguageSwitcherMobile" })(
  LanguageSwitcherMobile
);
