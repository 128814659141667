export default (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: ({ inCarousel }) => (inCarousel ? 0 : "40px"),
    paddingBottom: "40px",
  },
  adLabel: {
    textTransform: "uppercase",
    textAlign: "center",
    fontSize: "12px",
  },
  adLink: {
    display: "block",
    maxWidth: "600px",
  },
  adImg: {
    maxWidth: "600px",
    width: "100%",
    objectFit: "cover",
  },
});
