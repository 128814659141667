import React from "react";
import { withStyles } from "@material-ui/core";
import styles from "./sidebar.styles";
import LatestArticles from "../latest-articles";
import AdSidebar from "../ad-sidebar";

const Sidebar = (props) => {
  const { sidebarProps, classes } = props;
  const { sidebarAds } = sidebarProps;

  const ads =
    sidebarAds &&
    sidebarAds.map((ad, idx) => {
      return <AdSidebar key={idx} {...ad} />;
    });

  return (
    <div className={classes.root}>
      <LatestArticles articles={sidebarProps.latestArticles} />
      {ads}
    </div>
  );
};

export default withStyles(styles, { name: "WindSidebar" })(Sidebar);
