import React from "react";
import { withStyles, makeStyles } from "@material-ui/core";
import styles from "./category-section.styles";
import { getLanguageValues } from "../../util";
import SideArticles from "./side-articles";
import Article from "../article";

const useArticleStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("lg")]: {
      maxWidth: "400px",
    },
  },
  content: {},
  articleLink: {
    marginTop: "16px",
    marginBottom: "16px",
    fontSize: "30px",
    lineHeight: "34px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      lineHeight: "26px",
    },
  },
}));

const CategorySection = ({ category, classes }) => {
  const articleClasses = useArticleStyles();
  const { articles, name, nameTc, nameSc, slug } = category;
  const nameLocalized = getLanguageValues(name, nameTc, nameSc);
  const categoryArticle = articles[0];
  const sideArticles = articles.slice(1);

  return (
    <div className={classes.root}>
      <h1 className={classes.categoryName}>
        <a href={`/category/${slug}`}>{nameLocalized}</a>
      </h1>
      <div className={classes.articlesContainer}>
        <Article article={categoryArticle} classes={articleClasses} />
        <div className={classes.sideArticles}>
          <SideArticles articles={sideArticles} classes={classes} />
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles, { name: "CategorySection" })(CategorySection);
