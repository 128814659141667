import React from "react";
import { AdBanner, Area, CategorySection, HomeArticles } from "../components";
import { COMPONENT_TYPES } from "../constants";

const Content = ({ content }) => {
  switch (content.__component) {
    case COMPONENT_TYPES.AD:
      return <AdBanner {...content} />;
    case COMPONENT_TYPES.AREA:
      return <Area {...content} />;
    case COMPONENT_TYPES.CATEGORY_SECTION:
      return <CategorySection {...content} />;
    case COMPONENT_TYPES.HOME_ARTICLES:
      return <HomeArticles {...content} />;
    default:
      return null;
  }
};

export default Content;
