export default (theme) => ({
  root: {},
  accordion: {
    backgroundColor: theme.palette.primary.darkBlueGray,
    borderRadius: 0,
    boxShadow: "none",
  },
  languageSummary: {
    padding: 0,
    minHeight: "0 !important",
    "& h3": {
      margin: 0,
      fontSize: "22px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "22px",
      },
    },
    "& .MuiIconButton-edgeEnd": {
      marginRight: 0,
    },
  },
  summaryContent: {
    margin: 0,
  },
  summaryExpanded: {
    margin: "0 !important",
  },
  summaryIcon: {
    color: "#FFF",
    padding: 0,
    "& svg": {
      fontSize: "28px",
    },
  },
  languageDetails: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
  },
  languageOption: {
    display: "flex",
    alignItems: "center",
    "& p": {
      color: "#FFF",
      fontFamily: theme.typography.fontFamilyLexend,
      cursor: "pointer",
      fontSize: "18px",
      marginTop: "14px",
      marginBottom: "14px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "20px",
      },
    },
  },
  activeLanguage: {
    "& p": {
      color: "rgb(0, 197, 133)",
    },
  },
  activeLangIcon: {
    color: "rgb(0, 197, 133)",
    marginLeft: "20px",
    fontSize: "20px",
  },
});
