export default (theme) => ({
  root: {
    overflow: "auto",
    [theme.breakpoints.up("xl")]: {
      marginRight: "50px",
    },
  },
  articleInfo: {},
  // Article Info
  articleCategory: {
    width: "fit-content",
    marginTop: "16px",
    marginBottom: "16px",
    color: theme.palette.primary.secondary,
    "&:hover": {
      color: theme.palette.primary.darkGray,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
      marginBottom: "12px",
    },
  },
  headline: {
    marginTop: "20px",
    marginBottom: "20px",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
      lineHeight: "34px",
      marginTop: "12px",
      marginBottom: "12px",
    },
  },
  mainInfo: {
    borderBottom: "2px solid #44AAF5",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  author: {},
  timestamps: {
    display: "flex",
    justifyContent: "space-between",
  },
  created: {},
  updated: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  // Article Carousel
  "@global": {
    ".slick-list": {
      width: "100%",
    },
    ".slick-arrow": {
      zIndex: 1,
      height: "30px",
      width: "30px",
      "&:before": {
        color: "black",
        fontSize: "28px",
      },
      [theme.breakpoints.down("md")]: {
        display: "none !important",
      },
    },
    ".slick-prev": {
      left: "34px",
    },
    ".slick-next": {
      right: "34px",
    },
    ".slick-dots": {
      "& li": {
        "& button:before": {
          fontSize: "12px",
          [theme.breakpoints.down("sm")]: {
            fontSize: "10px",
          },
        },
      },
      "& .slick-active": {
        "& button:before": {
          color: `${theme.palette.primary.secondary} !important`,
        },
      },
    },
  },
  carousel: {
    width: "100%",
    margin: "30px auto",
    "& .slick-disabled": {
      opacity: 0,
      pointerEvents: "none",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "20px auto",
      padding: "0",
    },
  },
  slide: {
    "&:focus": {
      outline: "none",
    },
  },
  slidePhoto: {
    width: "100%",
    objectFit: "cover",
  },
  caption: {
    fontSize: "15px",
    lineHeight: "20px",
  },
  // Article Content
  articleContent: {
    marginTop: "50px",
    marginBottom: "50px",
    "& p": {
      fontSize: "18px",
      lineHeight: "30px",
    },
    "& a": {
      color: theme.palette.primary.secondary,
      textDecoration: "underline",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "24px",
      marginBottom: "24px",
    },
  },
  socialShareContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "150px",
    marginBottom: "50px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "20px",
    },
  },
  socialShareButton: {
    transition: "0.1s ease-in",
    "& i": {
      color: theme.palette.primary.main,
      fontSize: "30px",
      "&:hover": {
        color: theme.palette.primary.secondary,
      },
    },
  },
  shareText: {
    "& p": {
      color: theme.palette.primary.secondary,
      fontSize: "14px",
      fontWeight: 600,
    },
  },
});
