export default (theme) => ({
  root: {
    zIndex: 1101,
    height: "100vh",
    width: "100vw",
    backgroundColor: "#FFF",
    position: "absolute",
    top: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "360px",
    textAlign: "center",
  },
  logoLink: {
    maxWidth: "280px",
    "& img": {
      width: "100%",
      objectFit: "cover",
    },
  },
  description: {
    lineHeight: "24px",
    color: theme.palette.primary.gray,
  },
  button: {
    boxShadow: "none",
    backgroundColor: theme.palette.primary.secondary,
    textTransform: "none",
    color: "#FFF",
    fontSize: "16px",
    fontWeight: 500,
    "&:hover": {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
  help: {
    lineHeight: "24px",
    color: theme.palette.primary.gray,
  },
});
