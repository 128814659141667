import fetch from "isomorphic-unfetch";
import qs from "qs";
import regeneratorRuntime from "regenerator-runtime";

const API_URL = "https://wind-strapi.herokuapp.com";

class ContentModel {
  static async fetchBySlug(slug) {
    const response = await fetch(`${API_URL}/${slug}`);
    const json = await response.json();
    return json;
  }

  static async fetchArticle(slug) {
    const response = await fetch(`${API_URL}/articles/${slug}`);
    if (response.status === 404 || response.status === 500)
      return { status: response.status, statusText: response.statusText };
    const json = await response.json();
    return json;
  }

  static async fetchCategory(slug) {
    const response = await fetch(`${API_URL}/categories/${slug}`);
    if (response.status === 404 || response.status === 500)
      return { status: response.status, statusText: response.statusText };
    const json = await response.json();
    return json;
  }

  static async fetchCategoryArticles(options) {
    const query = qs.stringify(options);
    const response = await fetch(`${API_URL}/articles?${query}`);
    if (response.status === 404)
      return { status: response.status, statusText: response.statusText };
    const json = await response.json();
    return json;
  }
}

export default ContentModel;
