import React from "react";
import { withStyles } from "@material-ui/core";
import styles from "./wide-articles.styles";
import WideArticle from "./wide-article";

const WideArticles = ({ articles, classes }) => {
  const wideArticles = articles.map((article, index) => (
    <WideArticle key={index} classes={classes} {...article} />
  ));

  return <div className={classes.root}>{wideArticles}</div>;
};

export default withStyles(styles, { name: "WideArticles" })(WideArticles);
