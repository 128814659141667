import React from "react";

const ConditionalWrapper = ({ children, linkClass, link }) => {
  return link ? (
    <a className={linkClass} href={link} target="_blank">
      {children}
    </a>
  ) : (
    children
  );
};

export default ConditionalWrapper;
