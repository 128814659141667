import React from "react";
import Place from "./place";

const Places = ({ places, classes }) => {
  return (
    places &&
    places.map((place, index) => {
      return <Place key={index} place={place} classes={classes} />;
    })
  );
};

export default Places;
