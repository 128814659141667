import React from "react";
import { useHistory } from "react-router-dom";
import { Button, withStyles } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import styles from "./not-found.styles";
import { getLanguageValues } from "../../util";

const NotFound = (props) => {
  const {
    classes,
    description,
    descriptionTc,
    descriptionSc,
    help,
    helpTc,
    helpSc,
    logo,
  } = props;
  const history = useHistory();
  const descriptionLocalized = getLanguageValues(
    description,
    descriptionTc,
    descriptionSc
  );
  const helpLocalized = getLanguageValues(help, helpTc, helpSc);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {logo && logo.url && (
          <a className={classes.logoLink} href="/">
            <img src={logo.url} />
          </a>
        )}
        {descriptionLocalized && (
          <p className={classes.description}>{descriptionLocalized}</p>
        )}
        <Button
          className={classes.button}
          startIcon={<HomeIcon />}
          variant="contained"
        >
          <a href="/">Back Home</a>
        </Button>
        {helpLocalized && <p className={classes.help}>{helpLocalized}</p>}
      </div>
    </div>
  );
};

export default withStyles(styles, { name: "NotFound" })(NotFound);
