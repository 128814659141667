import React from "react";
import { useScrollTrigger, Slide } from "@material-ui/core";

const HideOnScroll = ({ children, enabled }) => {
  const trigger = useScrollTrigger();
  if (!enabled) {
    return children;
  }
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

export default HideOnScroll;
