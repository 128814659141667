import { createTheme } from "@material-ui/core/styles";

const windTheme = createTheme({
  palette: {
    primary: {
      main: "#1c2d3f",
      secondary: "#3fa3ea",
      darkBlueGray: "#222B37",
      gray: "#666666",
      activeGreen: "rgb(0,197,133)",
      darkGray: "#555555",
      darkNavy: "#151B26",
      altPurple: "#796eff",
    },
  },
  typography: {
    fontFamily: ["Open Sans", "sans-serif", "Roboto"].join(","),
    fontFamilyLexend: ["Lexend", "sans-serif", "Roboto"].join(","),
    h1: {
      fontFamily: ["Lexend", "sans-serif", "Roboto"].join(","),
      fontSize: "40px",
      lineHeight: "42px",
      fontWeight: 500,
    },
    h2: {
      fontFamily: ["Lexend", "sans-serif", "Roboto"].join(","),
      fontSize: "26px",
      lineHeight: "30px",
      fontWeight: 600,
    },
    h3: {
      fontFamily: ["Open Sans", "sans-serif", "Roboto"].join(","),
      fontSize: "20px",
      lineHeight: "26px",
      fontWeight: 600,
    },
    h4: {
      fontFamily: ["Open Sans", "sans-serif", "Roboto"].join(","),
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: 500,
    },
    p: {
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 324,
      md: 600,
      lg: 764,
      xl: 1024,
    },
  },
});

windTheme.overrides = {
  MuiContainer: {
    root: {
      [windTheme.breakpoints.up("lg")]: {
        maxWidth: "1260px",
      },
      [windTheme.breakpoints.down("sm")]: {
        paddingLeft: "20px",
        paddingRight: "20px",
      },
    },
  },
  MuiCssBaseline: {
    "@global": {
      h1: {
        ...windTheme.typography.h1,
        color: windTheme.palette.primary.main,
      },
      h2: {
        ...windTheme.typography.h2,
        color: windTheme.palette.primary.main,
      },
      h3: {
        ...windTheme.typography.h3,
        color: windTheme.palette.primary.secondary,
      },
      h4: {
        ...windTheme.typography.h4,
        color: windTheme.palette.primary.secondary,
      },
      p: {
        ...windTheme.typography.p,
        color: "#303030",
      },
      a: {
        cursor: "pointer",
        textDecoration: "none",
        color: "inherit",
        "&:visited": {
          color: "inherit",
        },
      },
    },
  },
};

export default windTheme;
