import React from "react";
import { withStyles } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import styles from "./page-numbers.styles";

const PageNumbers = (props) => {
  const { count, currentPage, paginate, classes } = props;

  return (
    <div className={classes.root}>
      <Pagination
        classes={{ ul: classes.pagination }}
        page={currentPage}
        count={count}
        size="large"
        onChange={paginate}
      />
    </div>
  );
};

export default withStyles(styles, { name: "PageNumbers" })(PageNumbers);
