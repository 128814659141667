export default (theme) => ({
  root: {
    margin: "36px 0",
    "& a:not(last-child)": {
      marginBottom: "24px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "24px 0",
    },
  },
  wideArticle: {
    backgroundColor: "#FFF",
    display: "flex",
    justifyContent: "space-between",
    borderRadius: "6px",
    padding: "20px",
    boxShadow: "0 1px 1px 1px rgb(10 16 34 / 8%)",
    [theme.breakpoints.up("md")]: {
      transition:
        "transform .2s ease,box-shadow .2s ease,-webkit-transform .2s ease,-webkit-box-shadow .2s ease",
      "&:hover": {
        transform: "translateY(-3px) translateZ(0)",
        boxShadow: "0 16px 32px 0 rgb(10 16 34 / 20%), 0 0 0 transparent",
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: "12px",
    },
  },
  wideContent: {
    paddingRight: "24px",
    [theme.breakpoints.down(380)]: {
      paddingRight: 0,
    },
  },
  wideHeadline: {
    margin: 0,
    fontSize: "24px",
    lineHeight: "26px",
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
      lineHeight: "22px",
    },
  },
  wideDate: {
    color: theme.palette.primary.secondary,
  },
  imgWrap: {
    maxWidth: "300px",
    "& img": {
      width: "100%",
      objectFit: "cover",
    },
    [theme.breakpoints.only("md")]: {
      maxWidth: "150px",
    },
  },
});
