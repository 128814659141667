import React from "react";
import Helmet from "react-helmet";

const Metadata = ({ title, description }) => {
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description}></meta>}
    </Helmet>
  );
};

export default Metadata;
