import React, { useState } from "react";
import {
  AppBar,
  Grid,
  Hidden,
  IconButton,
  useTheme,
  useMediaQuery,
  withStyles,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import styles from "./main-menu.styles";
import MenuDrawer from "../menu-drawer";
import MobileMenu from "./mobile-menu";
import HideOnScroll from "./hide-on-scroll";
import LanguageSwitcher from "../language/language-switcher";
import { getLanguageValues } from "../../util/";

const MainMenu = ({ header, hideSubNav = true, classes }) => {
  const { categories, links, headerLogo } = header;
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const toggleDrawer = (e) => {
    if (e.type === "keydown" && (e.key === "Tab" || e.key === "Shift")) return;
    setMenuOpen(!menuOpen);
  };

  const handleMenuClick = (e) => setAnchorEl(e.currentTarget);

  const handleMenuClose = () => setAnchorEl(null);

  const categoryLinks = categories.map((category, idx) => {
    const { name, nameTc, nameSc } = category;
    const nameLocalized = getLanguageValues(name, nameTc, nameSc);

    return (
      <Grid item key={idx} className={classes.menuLink}>
        <a href={`/category/${category.slug}`}>{nameLocalized}</a>
      </Grid>
    );
  });

  const headerLinks = links.map((link, idx) => {
    const { name, nameTc, nameSc } = link;
    const nameLocalized = getLanguageValues(name, nameTc, nameSc);

    return (
      <Grid item key={idx} className={classes.menuLink}>
        <a href={`/${link.slug}`}>{nameLocalized}</a>
      </Grid>
    );
  });

  const mobileIcon = anchorEl ? (
    <CloseIcon className={classes.togglerIcon} />
  ) : (
    <MenuIcon className={classes.togglerIcon} />
  );

  return (
    <div className={classes.root}>
      <HideOnScroll enabled={!isMobile}>
        <AppBar className={classes.appBar} position="fixed">
          <Hidden smDown>
            <MenuDrawer
              categories={categories}
              links={links}
              menuOpen={menuOpen}
              toggleDrawer={toggleDrawer}
            />
            <div className={classes.desktopContainer}>
              <IconButton
                className={classes.menuToggler}
                disableFocusRipple
                disableRipple
                onClick={toggleDrawer}
              >
                <MenuIcon className={classes.togglerIcon} />
              </IconButton>
              {headerLogo && (
                <div className={classes.headerLogo}>
                  <a href="/" className={classes.headerLogoLink}>
                    <img src={headerLogo.url} />
                  </a>
                </div>
              )}
              <LanguageSwitcher />
            </div>
            {!hideSubNav && (
              <div className={classes.linkContainer}>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  className={classes.gridContainer}
                >
                  {categories && categoryLinks}
                  {links && headerLinks}
                </Grid>
              </div>
            )}
          </Hidden>
          <Hidden mdUp>
            <div className={classes.mobileContainer}>
              <IconButton
                className={classes.menuToggler}
                disableFocusRipple
                disableRipple
                onClick={handleMenuClick}
              >
                {mobileIcon}
              </IconButton>
              {headerLogo && (
                <div className={classes.headerLogo}>
                  <a href="/" className={classes.headerLogoLink}>
                    <img src={headerLogo.url} />
                  </a>
                </div>
              )}
              <div className={classes.mobileSpacer}></div>
            </div>
          </Hidden>
        </AppBar>
      </HideOnScroll>
      <Hidden mdUp>
        <MobileMenu
          anchorEl={anchorEl}
          categories={categories}
          handleMenuClose={handleMenuClose}
          links={links}
          open={Boolean(anchorEl)}
        />
      </Hidden>
    </div>
  );
};

export default withStyles(styles, { name: "WindMainMenu" })(MainMenu);
