export default (theme) => ({
  root: {},
  overlay: {},
  drawer: {
    backgroundColor: theme.palette.primary.darkBlueGray,
    width: "360px",
    padding: "30px",
  },
  content: {},
  iconContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  drawerToggler: {
    paddingRight: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  drawerIcon: {
    color: "#FFF",
    fontSize: "26px",
  },
  drawerSection: {
    borderTop: "1px solid gray",
    paddingTop: "16px",
    paddingBottom: "16px",
  },
  sectionTitle: {
    fontSize: "22px",
    margin: 0,
  },
  splitLinks: {
    display: "flex",
    justifyContent: "space-between",
    paddingRight: "30px",
  },
  linksContainer: {},
  menuLink: {
    marginTop: "14px",
    marginBottom: "14px",
    "& a": {
      color: "#FFF",
      fontFamily: theme.typography.fontFamilyLexend,
      fontSize: "18px",
      lineHeight: "24px",
      "&:hover": {
        color: theme.palette.primary.secondary,
      },
    },
  },
});
