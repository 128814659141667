export default (theme) => ({
  root: {
    paddingTop: "30px",
    paddingBottom: "50px",
    [theme.breakpoints.up("lg")]: {
      maxWidth: "94%",
      margin: "0 auto",
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: "10px",
      paddingBottom: "10px",
    },
  },
  carousel: {
    "& .slick-disabled": {
      opacity: 0,
      pointerEvents: "none",
    },
  },
  slide: {
    "&:focus": {
      outline: "none",
    },
  },
  slidePhoto: {
    width: "100%",
    objectFit: "cover",
  },
  pageNums: {
    fontSize: "14px",
  },
  "@global": {
    ".slick-arrow": {
      zIndex: 1,
      height: "30px",
      width: "30px",
      "&:before": {
        color: "black",
        fontSize: "28px",
      },
      [theme.breakpoints.down("md")]: {
        display: "none !important",
      },
    },
    ".slick-prev": {
      left: "-34px",
    },
    ".slick-next": {
      right: "-34px",
    },
    ".slick-dots": {
      "& li": {
        "& button:before": {
          fontSize: "12px",
          [theme.breakpoints.down("sm")]: {
            fontSize: "10px",
          },
        },
      },
      "& .slick-active": {
        "& button:before": {
          color: `${theme.palette.primary.secondary} !important`,
        },
      },
    },
  },
});
