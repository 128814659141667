import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import ContentModel from "../../model";
import SidebarPage from "../../layouts/sidebar-page";
import { ArticleComponent, Metadata, NotFound } from "../../components";
import { getFirstParagraph } from "../../util";
import { NOT_FOUND_SLUG } from "../../constants";

const ArticlePage = ({ adCarousel, sidebarProps }) => {
  const { slug } = useParams();
  const [content, setContent] = useState(null);
  const [notFoundContent, setNotFoundContent] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await ContentModel.fetchArticle(slug);

      if (response.status === 404 || response.status === 500) {
        const notFoundResponse = await ContentModel.fetchBySlug(NOT_FOUND_SLUG);

        setNotFoundContent(notFoundResponse);
        setContent("error");
      } else {
        setContent(response);
      }
    };

    fetchData();
  }, [slug]);

  if (!content) return null;

  if (content === "error" && notFoundContent) {
    return <NotFound {...notFoundContent} />;
  }

  const metaTitle = content.headline || "Wind Newspaper | 風報";
  const metaDescription = getFirstParagraph(content.content) || "";

  return (
    <>
      <Metadata title={metaTitle} description={metaDescription} />
      <SidebarPage sidebarProps={sidebarProps}>
        <ArticleComponent {...content} adCarousel={adCarousel} />
      </SidebarPage>
    </>
  );
};

export default ArticlePage;
