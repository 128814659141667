import React, { useState, useEffect, Fragment } from "react";
import ContentModel from "../../model";
import Content from "../../content";
import { Container } from "@material-ui/core";
import { Description, Metadata, PageBanner } from "../../components";

const slug = "locations";

const LocationsPage = ({ links }) => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await ContentModel.fetchBySlug(slug);
      setContent(response);
    };

    fetchData();
  }, []);

  if (!content) return null;

  const {
    description,
    descriptionTc,
    descriptionSc,
    metaTitle,
    metaDescription,
  } = content;

  const titleMeta = metaTitle || "Wind Newspaper | 風報";
  const descriptionMeta = metaDescription || "";

  const linkInfo = links.find((link) => link.slug === slug);

  return (
    <>
      <Metadata title={titleMeta} description={descriptionMeta} />
      {linkInfo && <PageBanner {...linkInfo} />}
      <Container maxWidth={false}>
        <Description
          description={description}
          descriptionTc={descriptionTc}
          descriptionSc={descriptionSc}
        />
        {content.content &&
          content.content.map((component, index) => (
            <Content key={`content-${index}`} content={component} />
          ))}
      </Container>
    </>
  );
};

export default LocationsPage;
