import React from "react";
import { formatDate, getLanguageValues } from "../../util";

const SideArticles = ({ articles, classes }) => {
  return articles.map((article, index) => {
    const { createdAt, headline, headlineTc, headlineSc, photos, slug } =
      article;
    const headlineLocalized = getLanguageValues(
      headline,
      headlineTc,
      headlineSc
    );

    return (
      <div key={index} className={classes.sideArticle}>
        <div className={classes.sideArticleContent}>
          <h3 className={classes.articleLink}>
            <a href={`/article/${slug}`}>{headlineLocalized}</a>
          </h3>
          {createdAt && <p>{formatDate(createdAt)}</p>}
        </div>
      </div>
    );
  });
};

export default SideArticles;
