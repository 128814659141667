import {
  ABOUT_US_LANGUAGES,
  ARTICLES_LANGUAGES,
  CONTACT_US_LANGUAGES,
} from "../constants";
import getLanguageValues from "./getLanguageValues";

const getSectionTitles = () => {
  const sections = [
    ARTICLES_LANGUAGES,
    ABOUT_US_LANGUAGES,
    CONTACT_US_LANGUAGES,
  ];
  return sections.map((section) =>
    getLanguageValues(section.en, section.tc, section.sc)
  );
};

export default getSectionTitles;
