export default (theme) => ({
  root: {
    paddingTop: "40px",
  },
  adLabel: {
    textTransform: "uppercase",
    textAlign: "center",
    fontSize: "12px",
  },
});
