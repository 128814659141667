import React from "react";
import { withStyles } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import styles from "./latest-articles.styles";
import { formatDate, getLanguageValues, handleComponentData } from "../../util";

const LatestArticles = ({ articles, classes }) => {
  const { name, nameTc, nameSc } = articles;
  const latestArticles = handleComponentData(articles.articles, "article");
  const headerLocalized = getLanguageValues(name, nameTc, nameSc);

  return (
    <div className={classes.root}>
      {headerLocalized && (
        <h3 className={classes.articlesHeader}>{headerLocalized}</h3>
      )}
      {latestArticles &&
        latestArticles.map((article, index) => {
          if (!article) return null;
          const { headline, headlineTc, headlineSc, slug, createdAt } = article;

          const headlineLocalized = getLanguageValues(
            headline,
            headlineTc,
            headlineSc
          );

          return (
            <div key={index} className={classes.latestArticle}>
              <div className={classes.articleTop}>
                <FiberManualRecordIcon className={classes.articleIcon} />
                {headlineLocalized && (
                  <a href={`/article/${slug}`} className={classes.articleLink}>
                    <p>{headlineLocalized}</p>
                  </a>
                )}
              </div>
              {createdAt && (
                <p className={classes.articleDate}>
                  {formatDate(createdAt, false, true)}
                </p>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default withStyles(styles, { name: "LatestArticles" })(LatestArticles);
