import React from "react";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import { formatDate, getLanguageValues } from "../../util";
import ArticleCard from "../article-card";

const useArticleCardStyles = makeStyles((theme) => ({
  root: {
    margin: "20px 0",
  },
  cardHeadline: {
    marginBottom: "16px",
  },
}));

const WideArticle = (props) => {
  const {
    classes,
    category,
    createdAt,
    headline,
    headlineTc,
    headlineSc,
    photos,
    slug,
  } = props;

  const photo = photos && photos[0];
  const headlineLocalized = getLanguageValues(headline, headlineTc, headlineSc);

  const theme = useTheme();
  const isSmallBreakpoint = useMediaQuery(theme.breakpoints.down("sm"));
  const articleCardClasses = useArticleCardStyles();

  return isSmallBreakpoint ? (
    <ArticleCard
      classes={articleCardClasses}
      category={category}
      createdAt={createdAt}
      headline={headline}
      headlineTc={headlineTc}
      headlineSc={headlineSc}
      photos={photos}
      slug={slug}
    />
  ) : (
    <a href={`/article/${slug}`} className={classes.wideArticle}>
      <div className={classes.wideContent}>
        {headlineLocalized && (
          <h2 className={classes.wideHeadline}>{headlineLocalized}</h2>
        )}
        {createdAt && (
          <p className={classes.wideDate}>{formatDate(createdAt)}</p>
        )}
      </div>
      <div className={classes.imgWrap}>
        <img src={photo.photo.url} />
      </div>
    </a>
  );
};

export default WideArticle;
