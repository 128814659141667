import React from "react";
import { withStyles } from "@material-ui/core";
import styles from "./article-card.styles";
import { formatDate, getFirstParagraph, getLanguageValues } from "../../util";

const ArticleCard = (props) => {
  const {
    classes,
    category,
    content,
    contentTc,
    contentSc,
    createdAt,
    headline,
    headlineTc,
    headlineSc,
    photos,
    slug,
  } = props;

  const photo = photos.length && photos[0];
  const headlineLocalized = getLanguageValues(headline, headlineTc, headlineSc);
  const contentLocalized = getLanguageValues(content, contentTc, contentSc);

  const snippet = contentLocalized && getFirstParagraph(contentLocalized);

  return (
    <a href={`/article/${slug}`} className={classes.root}>
      <div className={classes.imgWrap}>
        <img src={photo.photo.url} />
      </div>
      <div className={classes.cardContent}>
        {headlineLocalized && (
          <h2 className={classes.cardHeadline}>{headlineLocalized}</h2>
        )}
        {snippet && (
          <div className={classes.cardSnippet}>
            <p>{snippet}</p>
          </div>
        )}
        {createdAt && (
          <span className={classes.cardDate}>{formatDate(createdAt)}</span>
        )}
      </div>
    </a>
  );
};

export default withStyles(styles, { name: "ArticleCard" })(ArticleCard);
