export default (theme) => ({
  root: {
    borderTop: "3px solid #3fa3ea",
    borderBottom: "3px solid #3fa3ea",
  },
  categoryName: {
    margin: 0,
    paddingTop: "12px",
    paddingBottom: "12px",
    width: "fit-content",
    "&:hover": {
      color: theme.palette.primary.darkGray,
    },
  },
  articlesContainer: {
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "space-between",
    },
  },
  sideArticles: {
    [theme.breakpoints.up("lg")]: {
      paddingRight: "20px",
    },
  },
  sideArticle: {},
  sideArticleContent: {},
  articleLink: {
    "& a": {
      "&:hover": {
        color: theme.palette.primary.gray,
      },
    },
  },
});
