import React from "react";
import { Container, withStyles } from "@material-ui/core";
import styles from "./page-banner.styles";
import { getLanguageValues } from "../../util";

const PageBanner = ({ name, nameTc, nameSc, classes }) => {
  const nameLocalized = getLanguageValues(name, nameTc, nameSc);

  return (
    <div className={classes.root}>
      <Container maxWidth={false}>
        {nameLocalized && (
          <h1 className={classes.pageBanner}>{nameLocalized}</h1>
        )}
      </Container>
    </div>
  );
};

export default withStyles(styles, { name: "PageBanner" })(PageBanner);
