import React from "react";
import { Box, withStyles } from "@material-ui/core";
import styles from "./area.styles";
import Places from "./places";
import { getLanguageValues } from "../../util";

const Area = ({ classes, name, nameTc, nameSc, places }) => {
  const nameLocalized = getLanguageValues(name, nameTc, nameSc);
  return (
    <Box className={classes.areaBanner}>
      <h2 className={classes.areaName}>{nameLocalized}</h2>
      <div className={classes.placesContainer}>
        <Places places={places} classes={classes} />
      </div>
    </Box>
  );
};

export default withStyles(styles, { name: "Area" })(Area);
