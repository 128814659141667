export default (theme) => ({
  root: {
    backgroundColor: theme.palette.primary.secondary,
    display: "flex",
    alignItems: "center",
    minHeight: "50px",
    paddingTop: "16px",
    paddingBottom: "16px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "10px",
      paddingBottom: "10px",
    },
  },
  pageBanner: {
    margin: 0,
    color: "#FFFFFF",
    fontSize: "48px",
    lineHeight: "52px",
    fontWeight: 800,
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
      lineHeight: "34px",
    },
  },
});
