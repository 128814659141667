import React from "react";
import { withStyles } from "@material-ui/core";
import Slider from "react-slick";
import AdBanner from "../ad-banner";
import styles from "./ad-carousel.styles";

const AdCarousel = (props) => {
  const { ads, classes } = props;

  const carouselSettings = {
    autoplay: true,
    autoplaySpeed: 8000,
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    speed: 2000,
    swipe: false,
  };

  return (
    <div className={classes.root}>
      <div className={classes.adLabel}>Advertisement</div>
      <Slider {...carouselSettings}>
        {ads && ads.map((ad, idx) => <AdBanner key={idx} {...ad} inCarousel />)}
      </Slider>
    </div>
  );
};

export default withStyles(styles, { name: "AdCarousel" })(AdCarousel);
