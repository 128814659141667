export default (theme) => ({
  root: {},
  imgLink: {},
  mainPhoto: {
    width: "100%",
    objectFit: "cover",
  },
  content: {},
  articleLink: {
    marginTop: "20px",
    marginBottom: "20px",
    "&:hover": {
      color: theme.palette.primary.darkGray,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      lineHeight: "26px",
      fontWeight: 600,
    },
  },
  snippet: {},
});
