import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider, useMediaQuery } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import windTheme from "./theme/windTheme";
import ContentModel from "./model";
import { MainMenu, Footer, LanguageProvider } from "./components";
import {
  ArticlePage,
  CategoryPage,
  Homepage,
  LocationsPage,
  NotFoundPage,
  WeeklyIssuePage,
} from "./pages";
import "./index.css";

const slug = "content";

const App = () => {
  const [content, setContent] = useState(null);
  const [currentPage, setCurrentPage] = useState("");
  const isLgDown = useMediaQuery(windTheme.breakpoints.down("lg"));

  useEffect(() => {
    const fetchData = async () => {
      const response = await ContentModel.fetchBySlug(slug);

      setContent(response);
    };

    setCurrentPage(window.location.pathname);

    fetchData();
  }, [window.location.href]);

  if (!content) return null;

  const links = content.header.links;
  const sidebarProps = {
    sidebarAds: content.sidebarAds,
    articlesHeader: content.articlesHeader,
    articlesHeaderTc: content.articlesHeaderTc,
    articlesHeaderSc: content.articlesHeaderSc,
    latestArticles: content.latestArticles,
  };

  return (
    <LanguageProvider>
      <ThemeProvider theme={windTheme}>
        <CssBaseline />
        <MainMenu header={content.header} />
        <Router>
          <Switch>
            <Route
              exact
              path="/"
              component={() => <Homepage sidebarProps={sidebarProps} />}
            />
            <Route
              exact
              path="/locations"
              component={() => <LocationsPage links={links} />}
            />
            <Route exact path="/weekly-issue" component={WeeklyIssuePage} />
            <Route
              exact
              path="/article/:slug"
              component={() => (
                <ArticlePage
                  adCarousel={content.adCarousel}
                  sidebarProps={sidebarProps}
                />
              )}
            />
            <Route
              exact
              path="/category/:slug/:pageNum?"
              component={() => <CategoryPage sidebarProps={sidebarProps} />}
            />
            <Route exact path="/:slug+" component={NotFoundPage} />
          </Switch>
        </Router>
        <Footer footer={content.footer} />
      </ThemeProvider>
    </LanguageProvider>
  );
};

export default App;
