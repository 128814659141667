import React, { useState, useRef } from "react";
import { Hidden, useMediaQuery, useTheme, withStyles } from "@material-ui/core";
import Slider from "react-slick";
import styles from "./weekly-issue.styles";

const WeeklyIssue = (props) => {
  const { classes, pages } = props;
  const [pageNum, setPageNum] = useState(1);
  const sliderRef = useRef();
  const theme = useTheme();
  const isLargeDown = useMediaQuery(theme.breakpoints.down("lg"));

  const handleAfterChange = (current) => {
    if (isLargeDown) setPageNum(current + 1);
  };

  const carouselSettings = {
    arrows: true,
    dots: !isLargeDown,
    infinite: false,
    slidesToShow: isLargeDown ? 1 : 2,
    slidesToScroll: isLargeDown ? 1 : 2,
    speed: 500,
    afterChange: (current) => handleAfterChange(current),
  };

  return (
    <div className={classes.root}>
      <Slider
        {...carouselSettings}
        className={classes.carousel}
        ref={sliderRef}
      >
        {pages &&
          pages.map((page, idx) => (
            <div key={idx} className={classes.slide}>
              <img className={classes.slidePhoto} src={page.photo.url} />
            </div>
          ))}
      </Slider>
      <Hidden xlUp>
        <p className={classes.pageNums}>
          Page {pageNum} of {pages.length}
        </p>
      </Hidden>
    </div>
  );
};

export default withStyles(styles, { name: "WeeklyIssue" })(WeeklyIssue);
