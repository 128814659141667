import React from "react";
import { Box } from "@material-ui/core";
import { getLanguageValues } from "../../util";

const Place = ({ place, classes }) => {
  const { name, nameTc, nameSc, address, cityZip } = place;
  const nameLocalized = getLanguageValues(name, nameTc, nameSc);

  return (
    <Box className={classes.place}>
      <h4 className={classes.placeName}>{nameLocalized}</h4>
      <p className={classes.placeAddress}>{address}</p>
      <p className={classes.cityZip}>{cityZip}</p>
    </Box>
  );
};

export default Place;
