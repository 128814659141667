const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const formatDate = (datetime, withTime = false, shortMonth = false) => {
  const dt = new Date(datetime);
  const monthString = MONTHS[dt.getMonth()];
  const year = dt.getFullYear();
  const date = dt.getDate();
  let hours = dt.getHours();
  let minutes = dt.getMinutes();
  const ampm = hours >= 12 ? "p.m." : "a.m.";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${
    !shortMonth ? monthString : `${monthString.slice(0, 3)}.`
  } ${date}, ${year} ${withTime ? `${hours}:${minutes} ${ampm}` : ""}`;
};

export default formatDate;
