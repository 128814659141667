export default (theme) => ({
  "@global": {
    ".MuiPopover-paper": {
      top: "80px !important",
      backgroundColor: "rgba(0, 0, 0, 0.85)",
      borderRadius: "5px",
    },
  },
  root: {},
  languageButton: {
    color: "#FFFFFF",
    width: "110px",
    textTransform: "none",
    fontSize: "16px",
    backgroundColor: theme.palette.primary.secondary,
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: "#3382BD",
    },
  },
  languageIcon: {
    marginRight: "4px",
  },
  languageOption: {
    color: "#FFFFFF",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  activeLanguage: {
    color: theme.palette.primary.activeGreen,
  },
  checkIcon: {
    color: theme.palette.primary.activeGreen,
    marginLeft: "6px",
  },
});
