import React, { useState, useEffect, Fragment } from "react";
import ContentModel from "../../model";
import SidebarPage from "../../layouts/sidebar-page";
import { Metadata } from "../../components";
import Content from "../../content";

const slug = "homepage";

const Homepage = ({ sidebarProps }) => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await ContentModel.fetchBySlug(slug);
      setContent(response);
    };

    fetchData();
  }, []);

  if (!content) return null;

  const title = content.metaTitle || "Wind Newspaper | 風報";
  const description = content.metaDescription || "";

  return (
    <>
      <Metadata title={title} description={description} />
      <SidebarPage sidebarProps={sidebarProps}>
        <div>
          {content.content &&
            content.content.map((component, index) => (
              <Content key={`content-${index}`} content={component} />
            ))}
        </div>
      </SidebarPage>
    </>
  );
};

export default Homepage;
