export default (theme) => ({
  root: {
    width: "100vw",
    position: "relative",
    left: "50%",
    right: "50%",
    marginLeft: "-50vw",
    marginRight: "-50vw",
    lineHeight: 0,
  },
});
