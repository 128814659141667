import React, { useState, useEffect, useContext, createContext } from "react";

const LanguageContext = createContext();

const useLanguageProvider = () => {
  return useContext(LanguageContext);
};

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("en");

  const setCurrentLanguage = (language) => {
    setLanguage(language);
    const prevLanguage = localStorage.getItem("language");
    if (language !== prevLanguage) localStorage.setItem("language", language);
  };

  useEffect(() => {
    const prevLanguage = localStorage.getItem("language");
    if (prevLanguage) setLanguage(prevLanguage);
  });

  return (
    <LanguageContext.Provider
      value={{ language: language, setCurrentLanguage }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageProvider, useLanguageProvider };
