import React, { useState } from "react";
import { Menu, Button, MenuItem, withStyles } from "@material-ui/core";
import LanguageIcon from "@material-ui/icons/Language";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useLanguageProvider } from "./language-provider";
import styles from "./language-switcher.styles";
import { LANGUAGE, LANGUAGE_FORMAT } from "../../constants";
import { getLanguageValues } from "../../util";

const LanguageSwitcher = ({ classes }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { language, setCurrentLanguage } = useLanguageProvider();

  const onLanguageSelect = (language) => {
    setCurrentLanguage(language);
    setAnchorEl(null);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleMenuOpen}
        className={classes.languageButton}
      >
        <LanguageIcon fontSize="small" className={classes.languageIcon} />
        {getLanguageValues(LANGUAGE.en, LANGUAGE.tc, LANGUAGE.sc)}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {language &&
          Object.keys(LANGUAGE_FORMAT).map((lang, index) => {
            const languageOption = LANGUAGE_FORMAT[lang];
            const isActiveLanguage = lang === language;
            return (
              <MenuItem
                key={index}
                className={`${classes.languageOption} ${
                  isActiveLanguage ? classes.activeLanguage : ""
                }`}
                onClick={() => onLanguageSelect(lang)}
              >
                {languageOption}
                {isActiveLanguage && (
                  <CheckCircleIcon
                    className={classes.checkIcon}
                    fontSize="small"
                  />
                )}
              </MenuItem>
            );
          })}
      </Menu>
    </div>
  );
};

export default withStyles(styles, { name: "LanguageSwitcher" })(
  LanguageSwitcher
);
