import React, { useState, useEffect, Fragment } from "react";
import { useHistory, useParams } from "react-router-dom";
import ContentModel from "../../model";
import SidebarPage from "../../layouts/sidebar-page";
import {
  BannerArticle,
  Metadata,
  NotFound,
  PageBanner,
  PageNumbers,
  WideArticles,
} from "../../components";
import { NOT_FOUND_SLUG } from "../../constants";

const CategoryPage = (props) => {
  const { sidebarProps } = props;
  const { slug, pageNum } = useParams();
  const history = useHistory();
  const [content, setContent] = useState(null);
  const [articleContent, setArticleContent] = useState(null);
  const [notFoundContent, setNotFoundContent] = useState(null);
  const [currentPage, setCurrentPage] = useState(
    pageNum ? parseInt(pageNum) : 1
  );

  const articlesPerPage = 5;
  const articleStart =
    currentPage <= 1 ? 0 : (currentPage - 1) * articlesPerPage;

  const options = {
    "category.slug": slug,
    _limit: articlesPerPage,
    _start: articleStart,
    _sort: "createdAt:DESC",
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await ContentModel.fetchCategory(slug);

      if (response.status === 404 || response.status === 500) {
        const notFoundResponse = await ContentModel.fetchBySlug(NOT_FOUND_SLUG);

        setNotFoundContent(notFoundResponse);
        setContent("error");
      } else {
        setContent(response);
        const articleResponse = await ContentModel.fetchCategoryArticles(
          options
        );

        if (
          articleResponse.status === 404 ||
          articleResponse.status === 500 ||
          !articleResponse.length
        ) {
          const notFoundResponse2 = await ContentModel.fetchBySlug(
            NOT_FOUND_SLUG
          );

          setNotFoundContent(notFoundResponse2);
          setArticleContent("error");
        } else {
          setArticleContent(articleResponse);
        }
      }
    };
    fetchData();
  }, [slug, currentPage]);

  const paginate = (e, pageNumber) => {
    e.preventDefault();
    const pageRoute = pageNumber === 1 ? `/${slug}` : `/${slug}/${pageNumber}`;
    setCurrentPage(pageNumber);
    history.push(`/category${pageRoute}`);
    window.scrollTo(0, 0);
  };

  if (content === "error" || articleContent === "error") {
    return <NotFound {...notFoundContent} />;
  }

  if (!content || !articleContent) return null;

  const metaTitle = content.metaTitle || `${content.name} | Wind Newspaper`;
  const metaDescription = content.metaDescription || "";

  const { totalArticles } = content;
  const count = Math.ceil(totalArticles / articlesPerPage);
  const articles = articleContent;
  const isFirstPage = currentPage === 1;

  return (
    <>
      <Metadata title={metaTitle} description={metaDescription} />
      <PageBanner {...content} />
      {isFirstPage && <BannerArticle article={isFirstPage && articles[0]} />}
      <SidebarPage sidebarProps={sidebarProps}>
        <div>
          <WideArticles articles={isFirstPage ? articles.slice(1) : articles} />
          <PageNumbers
            currentPage={currentPage}
            count={count}
            paginate={paginate}
          />
        </div>
      </SidebarPage>
    </>
  );
};

export default CategoryPage;
