import React from "react";
import { withStyles } from "@material-ui/core";
import styles from "./ad-sidebar.styles";
import ConditionalWrapper from "../conditional-wrapper";

const AdSidebar = (props) => {
  const { classes, link, image } = props;

  return (
    <div className={classes.root}>
      <ConditionalWrapper link={link} linkClass={classes.adLink}>
        {image && image.url && (
          <img className={classes.adImg} src={image.url} />
        )}
      </ConditionalWrapper>
    </div>
  );
};

export default withStyles(styles, { name: "AdSidebar" })(AdSidebar);
