export default (theme) => ({
  root: {
    marginTop: "50px",
    marginBottom: "40px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "30px",
      marginBottom: "30px",
    },
  },
  pagination: {
    justifyContent: "center",
  },
});
