export default (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "6px",
    backgroundColor: "#FFF",
    boxShadow: "0 1px 1px 1px rgb(10 16 34 / 8%)",
    [theme.breakpoints.up("md")]: {
      transition:
        "transform .2s ease,box-shadow .2s ease,-webkit-transform .2s ease,-webkit-box-shadow .2s ease",
      "&:hover": {
        transform: "translateY(-3px) translateZ(0)",
        boxShadow: "0 16px 32px 0 rgb(10 16 34 / 20%), 0 0 0 transparent",
      },
    },
  },
  imgWrap: {
    width: "100%",
    height: "auto",
    "& img": {
      width: "100%",
      height: "auto",
      borderRadius: "6px 6px 0 0",
    },
  },
  cardContent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "24px",
    [theme.breakpoints.down("sm")]: {
      padding: "12px",
    },
  },
  cardHeadline: {
    margin: 0,
    fontSize: "24px",
    lineHeight: "26px",
  },
  cardSnippet: {
    flex: 1,
    "& p": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-box-orient": "vertical",
      "-webkit-line-clamp": 4,
    },
  },
  cardDate: {
    paddingTop: "10px",
    borderTop: "1px solid #E4E4EB",
    color: theme.palette.primary.secondary,
  },
});
