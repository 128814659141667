import React from "react";
import { useTheme, useMediaQuery, withStyles } from "@material-ui/core";
import ReactMarkdown from "react-markdown";
import styles from "./article.styles";
import { getFirstParagraph, getLanguageValues } from "../../util";
import FullBleed from "../full-bleed";
import LinkRenderer from "../../renderers/link-renderer";

const Article = ({ article, classes, fullWidthImg = false }) => {
  const {
    content,
    contentTc,
    contentSc,
    createdAt,
    headline,
    headlineTc,
    headlineSc,
    photos,
    slug,
  } = article;

  const theme = useTheme();
  const isSmallBreakpoint = useMediaQuery(theme.breakpoints.down("sm"));
  const mainPhoto = photos[0];
  const headlineLocalized = getLanguageValues(headline, headlineTc, headlineSc);
  const contentLocalized = getLanguageValues(content, contentTc, contentSc);
  const snippet = getFirstParagraph(contentLocalized);

  return (
    <div className={classes.root}>
      {mainPhoto && (
        <FullBleed fullWidth={fullWidthImg && isSmallBreakpoint}>
          <a className={classes.imgLink} href={`/article/${slug}`}>
            <img src={mainPhoto.photo.url} className={classes.mainPhoto} />
          </a>
        </FullBleed>
      )}
      <div className={classes.content}>
        {headlineLocalized && (
          <h1 className={classes.articleLink}>
            <a href={`/article/${slug}`}>{headlineLocalized}</a>
          </h1>
        )}
        {snippet && (
          <div className={classes.snippet}>
            <ReactMarkdown
              children={snippet}
              renderers={{ link: LinkRenderer }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles, { name: "Article" })(Article);
