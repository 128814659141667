const styles = (theme) => ({
  popoverPaper: {
    width: "100%",
    maxWidth: "100%",
    height: "100%",
    backgroundColor: theme.palette.primary.main,
    top: "96px !important",
    left: "0px !important",
    boxShadow: "none",
    borderRadius: 0,
    transition: "none !important",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  mobileMenu: {},
  mobileMenuSection: {
    borderTop: "1px solid gray",
    paddingTop: "16px",
    paddingBottom: "16px",
  },
  sectionTitle: {
    fontSize: "22px",
    margin: 0,
  },
  splitLinks: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "80%",
  },
  linksContainer: {},
  menuLink: {
    paddingTop: "14px",
    "& a": {
      color: "#FFF",
      fontFamily: theme.typography.fontFamilyLexend,
      fontSize: "20px",
      lineHeight: "24px",
    },
  },
});

export default styles;
