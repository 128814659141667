export default (theme) => ({
  cardGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(360px, 1fr))",
    gridAutoRows: "minmax(450px, auto)",
    gridGap: "36px",
    paddingTop: "24px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(auto-fill, minmax(260px, 1fr))",
      gridGap: "24px",
    },
  },
});
